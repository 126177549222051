import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import RadioStationInstructions from "../components/radio-station-instruction"

const HowTo = () => {
  return (
    <Layout location={"contact"} dark={true} title={"Contact Us"}>
      <div>
        <article
          className="blog-post w-full   mx-auto px-4 lg:px-2"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              className={`  mb-12 article-title text-center`}
              itemProp="headline"
            >
              How To
            </h2>
            <p className="text-xl mb-12 text-center">
              Need further assistance? Contact us via the{" "}
              <Link to="/contact" className="underline font-bold">
                contact form
              </Link>
              .
            </p>
          </header>

          <div className="  mb-32" itemProp="articleBody">
            <RadioStationInstructions />
          </div>

          {/* <footer>
          <Bio />
        </footer> */}
        </article>
      </div>
    </Layout>
  )
}

export default HowTo

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="How To Setup Your Radio Station" />
