import React from "react"

const RadioStationInstructions = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      {/* Table of Contents */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-700 mb-4">
          Table of Contents
        </h2>
        <ul className="list-disc pl-6 text-gray-600">
          <li>
            <a href="#menu-items" className="text-blue-600 hover:underline">
              Menu Items Overview
            </a>
          </li>
          <li>
            <a href="#program-grid" className="text-blue-600 hover:underline">
              Modifying Your Program Grid
            </a>
          </li>
          <li>
            <a href="#replace-audio" className="text-blue-600 hover:underline">
              How to Replace Audio from Your Radio On-Demand Recordings
            </a>
          </li>
          <li>
            <a href="#download-audio" className="text-blue-600 hover:underline">
              How to Download Audio from Your On-Demand Recordings
            </a>
          </li>
          <li>
            <a href="#add-users" className="text-blue-600 hover:underline">
              Adding Users to Your Station
            </a>
          </li>
        </ul>
      </div>

      <p className="text-gray-600 mb-8">
        MyRadio.Click provides a comprehensive set of tools to manage station
        details, program grids, episodes, and more. Below is a guide to the key
        menu items and how to use them, followed by detailed instructions for
        modifying your program grid, replacing on-demand audio, downloading
        on-demand recordings, and adding users to your station.
      </p>

      {/* Menu Items Overview */}
      <div className="mb-6" id="menu-items">
        <h2 className="text-lg font-semibold text-gray-700 bg-gray-200 p-4 rounded-t-lg">
          Menu Items Overview
        </h2>
        <ul className="list-decimal pl-6 bg-white p-4 rounded-b-lg text-gray-600">
          <li>
            <strong>Station Info:</strong> View and edit essential details about
            your radio station, such as name, description, and contact
            information.
          </li>
          <li>
            <strong>Edit Station:</strong> Adjust global settings for your
            station. These are typically set once and rarely need further
            modification.
          </li>
          <li>
            <strong>Website Widgets:</strong> Create customizable widgets (e.g.,
            now-playing info or schedules) to embed on your station’s website.
          </li>
          <li>
            <strong>Episodes:</strong> Access a searchable list of all aired and
            scheduled program episodes. Add metadata like custom titles, images,
            and playlists to enhance episode details.
          </li>
          <li>
            <strong>Segments:</strong> Create a feed of highlighted content from
            your stream.
          </li>
          <li>
            <strong>Programs:</strong> View and manage the complete list of your
            station’s programs.
          </li>
          <li>
            <strong>Grid:</strong> Organize your weekly schedule by assigning
            programs to specific time slots. Every aired show must have an entry
            here.
          </li>
          <li>
            <strong>Download Audio:</strong> Retrieve AAC audio files of
            on-demand recordings from the past two months.
          </li>
          <li>
            <strong>Replace Audio:</strong> Use this form to replace up to two
            hours of audio in your on-demand library at a time.
          </li>
          <li>
            <strong>Station Pre-Rolls:</strong> Upload an audio file to play
            before on-demand episodes, such as ads or station IDs.
          </li>
          <li>
            <strong>Reports:</strong> Review basic statistics about your live
            stream performance.
          </li>
          <li>
            <strong>Presenters:</strong> Manage users with permissions to edit
            episode information and download audio files.
          </li>
        </ul>
      </div>

      {/* Modifying Your Program Grid */}
      <div className="mb-6" id="program-grid">
        <h2 className="text-lg font-semibold text-gray-700 bg-gray-200 p-4 rounded-t-lg">
          Modifying Your Program Grid
        </h2>
        <div className="bg-white p-4 rounded-b-lg text-gray-600">
          <p className="mb-4">
            To update your station’s program grid, you’ll need to ensure
            programs exist in your system and then assign them to time slots.
            Follow these steps to add a program and place it in the grid.
          </p>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Adding a Program
          </h3>
          <ol className="list-decimal pl-6 mb-4">
            <li>
              Navigate to the <strong>Programs</strong> page from the main menu.
            </li>
            <li>
              Click <strong>Create Program</strong>.
            </li>
            <li>
              Enter a <strong>Program Title</strong> (required) and any optional
              details, such as a description or category.
            </li>
            <li>
              (Recommended) Upload a <strong>Program Image</strong> to enhance
              visibility and branding.
            </li>
            <li>Save the program to add it to your list.</li>
          </ol>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Adding a Program to the Grid
          </h3>
          <ol className="list-decimal pl-6 mb-4">
            <li>
              Go to the <strong>Grid</strong> page from the main menu.
            </li>
            <li>
              Scroll to the desired day and time slot to verify it’s empty. If a
              program already exists in that slot:
              <ul className="list-disc pl-6">
                <li>
                  Locate the row, click the red <strong>Trash Can</strong>{" "}
                  button, and confirm removal.
                </li>
              </ul>
            </li>
            <li>
              Return to the top of the page and fill out the scheduling form
              with the following details:
              <ul className="list-disc pl-6">
                <li>
                  <strong>Start Date</strong>: Leave as today’s date (default).
                </li>
                <li>
                  <strong>Day</strong>: Select the day of the week (e.g.,
                  Wednesday).
                </li>
                <li>
                  <strong>Start Time</strong>: Enter the start time (e.g., 12:00
                  PM).
                </li>
                <li>
                  <strong>Duration in Minutes</strong>: Specify the length
                  (e.g., 60 for a 1-hour show).
                </li>
                <li>
                  <strong>Program</strong>: Begin typing the program name (e.g.,
                  "Late Night Tunes") and select it from the dropdown list that
                  appears.
                </li>
              </ul>
            </li>
            <li>Submit the form to add the program to the grid.</li>
            <li>
              Verify the entry by scrolling to the scheduled day and time slot.
            </li>
          </ol>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Example: Scheduling "Late Night Tunes"
          </h3>
          <p>
            To schedule a 1-hour program called "Late Night Tunes" for Wednesday
            at 12:00 PM:
          </p>
          <ul className="list-disc pl-6">
            <li>
              Go to <strong>Programs</strong> and create "Late Night Tunes" if
              it doesn’t exist (include an optional image).
            </li>
            <li>
              Navigate to <strong>Grid</strong>, scroll to Wednesday, and ensure
              the 12:00 PM slot is free. Remove any conflicting entry using the{" "}
              <strong>Trash Can</strong> button.
            </li>
            <li>
              At the top, enter: Start Date (today), Day (Wednesday), Start Time
              (12:00 PM), Duration (60), and select "Late Night Tunes" from the
              Program field.
            </li>
            <li>Submit and confirm the slot is updated.</li>
          </ul>
        </div>
      </div>

      {/* How to Replace Audio */}
      <div className="mb-6" id="replace-audio">
        <h2 className="text-lg font-semibold text-gray-700 bg-gray-200 p-4 rounded-t-lg">
          How to Replace Audio from Your Radio On-Demand Recordings
        </h2>
        <div className="bg-white p-4 rounded-b-lg text-gray-600">
          <p className="mb-4">
            Use this feature to replace a broadcast or fix a recording failure
            in your on-demand library. Supported audio formats include WAV, AAC,
            and MP3 (OGG is not supported). The replacement process allows
            uploads up to 250 MB and is queued for processing, with email
            notifications sent when it begins and completes.
          </p>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Replacing Audio
          </h3>
          <ol className="list-decimal pl-6">
            <li>
              Navigate to the <strong>Replace Audio</strong> page from the main
              menu.
            </li>
            <li>
              Drag and drop your audio file (up to 250 MB) into the upload box
              at the top of the screen.
            </li>
            <li>
              Complete the form that appears with the following details:
              <ul className="list-disc pl-6">
                <li>
                  <strong>Start Time</strong>: Use the date/time picker to set
                  the exact start time of the audio you intend to replace.
                </li>
                <li>
                  <strong>Duration</strong>: Adjust the duration slider to
                  specify the length of time to replace (up to 2 hours). Note:
                  If your uploaded file exceeds the selected duration, the
                  excess will be disregarded.
                </li>
              </ul>
            </li>
            <li>
              Review the two audio players on the page:
              <ul className="list-disc pl-6">
                <li>
                  <strong>Audio to Replace With</strong>: Previews the file you
                  uploaded.
                </li>
                <li>
                  <strong>Currently On Server at Specified Time</strong>:
                  Previews the existing audio you’re replacing.
                </li>
              </ul>
            </li>
            <li>Double-check both players to ensure accuracy.</li>
            <li>
              Click the <strong>Replace Audio</strong> button to submit your
              request.
            </li>
            <li>
              Monitor progress:
              <ul className="list-disc pl-6">
                <li>
                  Your request will appear in the list of past jobs below the
                  form.
                </li>
                <li>
                  You’ll receive an email when the replacement starts and
                  another when it’s complete.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      {/* How to Download Audio */}
      <div className="mb-6" id="download-audio">
        <h2 className="text-lg font-semibold text-gray-700 bg-gray-200 p-4 rounded-t-lg">
          How to Download Audio from Your On-Demand Recordings
        </h2>
        <div className="bg-white p-4 rounded-b-lg text-gray-600">
          <p className="mb-4">
            Users can download AAC audio files from on-demand recordings (up to
            two months old) using the <strong>Download Audio</strong> feature.
            This tool allows you to preview and save specific segments of audio
            directly to your computer.
          </p>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Downloading Audio
          </h3>
          <ol className="list-decimal pl-6">
            <li>
              Navigate to the <strong>Download Audio</strong> page from the main
              menu.
            </li>
            <li>
              Fill out the form with the following details:
              <ul className="list-disc pl-6">
                <li>
                  <strong>Start Time</strong>: Use the date/time picker to set
                  the start of the audio segment you want.
                </li>
                <li>
                  <strong>Duration</strong>: Adjust the length of time you need
                  (e.g., in minutes).
                </li>
              </ul>
            </li>
            <li>
              Preview the audio:
              <ul className="list-disc pl-6">
                <li>
                  As you adjust the start time and duration, an audio player
                  will appear, allowing you to listen to the selected segment.
                </li>
              </ul>
            </li>
            <li>
              Once satisfied with the preview, click the{" "}
              <strong>Queue Download</strong> button.
            </li>
            <li>
              Wait for processing:
              <ul className="list-disc pl-6">
                <li>
                  A loading indicator will appear while the audio is prepared.
                </li>
                <li>
                  When complete, a download link will display on the screen.
                </li>
              </ul>
            </li>
            <li>Click the link to save the AAC audio file to your computer.</li>
          </ol>
        </div>
      </div>

      {/* Adding Users to Your Station */}
      <div className="mb-6" id="add-users">
        <h2 className="text-lg font-semibold text-gray-700 bg-gray-200 p-4 rounded-t-lg">
          Adding Users to Your Station
        </h2>
        <div className="bg-white p-4 rounded-b-lg text-gray-600">
          <p className="mb-4">
            You can grant presenters access to a simplified version of this
            system through an invite system under the{" "}
            <strong>Presenters</strong> menu. This section allows you to manage
            existing users and invite new ones to edit episode information and
            download audio.
          </p>
          <h3 className="text-xl font-medium text-gray-700 mb-2">
            Managing and Inviting Users
          </h3>
          <ol className="list-decimal pl-6">
            <li>
              Navigate to the <strong>Presenters</strong> page from the main
              menu.
            </li>
            <li>
              View the list of current users with access to your station.
              <ul className="list-disc pl-6">
                <li>
                  To revoke access, click <strong>Remove User</strong> next to
                  the desired user’s name.
                </li>
              </ul>
            </li>
            <li>
              To invite new users:
              <ul className="list-disc pl-6">
                <li>
                  Click the link titled{" "}
                  <strong>
                    Click here to invite presenters to use your station
                  </strong>
                  .
                </li>
                <li>
                  In the form that appears, enter a comma-separated list of
                  email addresses.
                </li>
                <li>
                  Press <strong>Enter</strong> to display the list for review.
                </li>
                <li>
                  Check the list, remove any incorrect emails, or add more as
                  needed.
                </li>
              </ul>
            </li>
            <li>
              Once the list is correct, click{" "}
              <strong>Create [number of invites] Invites</strong> to send the
              invitations.
            </li>
            <li>
              Monitor invite status:
              <ul className="list-disc pl-6">
                <li>
                  Below the form, view all previously created invites and their
                  statuses (e.g., pending, accepted).
                </li>
                <li>
                  For unaccepted invites, click <strong>Re-send</strong> next to
                  the user’s invite to send a new email if needed.
                </li>
              </ul>
            </li>
            <li>
              Invite outcomes:
              <ul className="list-disc pl-6">
                <li>
                  Existing users who have signed up will gain immediate access
                  to your station.
                </li>
                <li>
                  New users will receive an email with a sign-up link, valid for
                  24 hours.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default RadioStationInstructions
